import { data } from "./data";
import Card from "./Components/Card";
import Header from "./Components/Header";

export default function App() {

	const cards = data.map(item => {
		return (
			<Card key={item.id} info={item} />
		)
	})

	return (
		<>
			<Header />
			<div className="card-container">
				{cards}
			</div>
		</>
	)
}