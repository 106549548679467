export const data = [
	{
		id: 1,
		title: "Athens",
		location: "Greece",
		startDate: "01 July 2019",
		endDate: "08 July 2019",
		description: "Athens, the Greek capital, is surrounded in history. Temples, tombs, and monuments are all around you. You are never far from the door step of history.",
		imgUrl: "https://media.timeout.com/images/105738431/1024/576/image.jpg",
		imgAlt: "Athens"
	},
	{
		id: 2,
		title: "Rome",
		location: "Italy",
		startDate: "07 October 2018",
		endDate: "14 October 2018",
		description: "Rome, the Italian capital, rich in history. I travelled there for the first time for my 25th birthday.",
		imgUrl: "https://thumbs.dreamstime.com/b/rome-italy-colosseum-coliseum-sunrise-144201572.jpg",
		imgAlt: "Rome"
	},
	{
		id: 3,
		title: "Florence",
		location: "Italy",
		startDate: "14 October 2018",
		endDate: "18 October 2018",
		description: "Florence, a beautiful city.",
		imgUrl: "https://d2bgjx2gb489de.cloudfront.net/gbb-blogs/wp-content/uploads/2018/02/03115447/Sunset-view-of-Florence-and-Duomo_XXL.jpg",
		imgAlt: "Florence"
	},
	{
		id: 4,
		title: "Pisa",
		location: "Italy",
		startDate: "16 October 2018",
		endDate: false,
		description: "Home to the famous Leaning Tower of Pisa.",
		imgUrl: "https://cdn.thecrazytourist.com/wp-content/uploads/2017/09/ccimage-shutterstock_529910596.jpg",
		imgAlt: "Florence"
	},
	{
		id: 5,
		title: "Venice",
		location: "Italy",
		startDate: "18 October 2018",
		endDate: "22 October 2018",
		description: "Venice, the city of water.",
		imgUrl: "https://www.venezialines.com/wp-content/uploads/2021/01/castello-venice-walk.jpg",
		imgAlt: "Venice"
	},
	{
		id: 6,
		title: "Malcesine",
		location: "Lake Garda, Italy",
		startDate: "22 October 2018",
		endDate: "26 October 2018",
		description: "Malcesine on the coast of Lake Garda, a beautiful breathtaking place with sights of the Alps mountain ranges and rolling hills.",
		imgUrl: "https://www.rsaerosailing.org/images/news/1054935726.jpg",
		imgAlt: "Malcesine"
	},
]