import React from "react";

export default function Card(props) {
	return (
		<div className="card">
			<img className="card__img" src={props.info.imgUrl} alt={props.info.imgAlt} />
			<div className="card__content">
				<span className="card__location">
					<i className="fas fa-location-dot"></i>
					{props.info.location}
				</span>
				<h2 className="card__title">{props.info.title}</h2>
				<div className="card__date">
					<span className="card__date__startDate">{props.info.startDate}</span>
					{
					props.info.endDate
						&& <span className="card__date__endDate">{props.info.endDate}</span>
					}
				</div>
				<p className="card__description">{props.info.description}</p>
			</div>
		</div>
	)
}